// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._a-0 {\n  display: flex;\n}\n\n._a-1 {\n  flex-direction: row;\n}\n\n._a-1._a-2 {\n    flex-direction: row-reverse;\n  }\n._a-3 {\n  flex-direction: column;\n}\n._a-3._a-2 {\n    flex-direction: column-reverse;\n  }\n\n._a-4 {\n  align-items: center;\n}\n._a-5 {\n  align-items: flex-start;\n}\n._a-6 {\n  align-items: flex-end;\n}\n._a-7 {\n  align-items: stretch;\n}\n._a-8 {\n  align-items: baseline;\n}\n\n._a-9 {\n  align-self: center;\n}\n._a-a {\n  align-self: flex-start;\n}\n._a-b {\n  align-self: flex-end;\n}\n._a-c {\n  align-self: stretch;\n}\n._a-d {\n  align-self: baseline;\n}\n\n._a-e {\n  justify-content: center;\n}\n._a-f {\n  justify-content: flex-start;\n}\n._a-g {\n  justify-content: flex-end;\n}\n._a-h {\n  justify-content: space-between;\n}\n._a-i {\n  justify-content: space-around;\n}\n._a-j {\n  justify-content: space-evenly;\n}\n\n._a-k {\n  flex-wrap: wrap;\n}\n\n._a-l {\n  flex-wrap: nowrap;\n}\n\n._a-m {\n  flex-wrap: wrap-reverse;\n}\n", ""]);
// Exports
export var flex = "_a-0";
export var row = "_a-1";
export var reverse = "_a-2";
export var column = "_a-3";
export var alignCenter = "_a-4";
export var alignFlexStart = "_a-5";
export var alignFlexEnd = "_a-6";
export var alignStretch = "_a-7";
export var alignBaseline = "_a-8";
export var alignSelfCenter = "_a-9";
export var alignSelfFlexStart = "_a-a";
export var alignSelfFlexEnd = "_a-b";
export var alignSelfStretch = "_a-c";
export var alignSelfBaseline = "_a-d";
export var justifyCenter = "_a-e";
export var justifyFlexStart = "_a-f";
export var justifyFlexEnd = "_a-g";
export var justifySpaceBetween = "_a-h";
export var justifySpaceAround = "_a-i";
export var justifySpaceEvenly = "_a-j";
export var wrapWrap = "_a-k";
export var wrapNowrap = "_a-l";
export var wrapWrapReverse = "_a-m";
export default ___CSS_LOADER_EXPORT___;
